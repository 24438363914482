<template>
  <div id="app" class="app">

  <div class="app__widget" @mousedown.self="Reverse" v-bind:style="widget">
    <div class="app__logo">

<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 511.3 220.9" xml:space="preserve" ref="logo">
  <path class="app__gd" d="M412.3 168.2v48h-28.1v-43.5c0-12-6.1-17.7-15.4-17.7-5 0-10.4 2.8-15.3 8.3v52.9h-28.3v-84.1h28.3v5.5c6.8-5.2 14.1-8.1 23.1-8.1 21.2 0 35.7 14.7 35.7 38.7zM507.5 182.4h-59.8c2.3 7.6 8.1 13.5 18.9 13.5 5.9 0 10.4-2.3 12.8-6.1h27.9c-4.2 17.3-20.3 28.3-41.1 28.3-27.6 0-45.9-18.4-45.9-44 0-25.5 17.9-43.9 44.9-43.9 24.3 0 42.8 16.8 42.8 42.1a57 57 0 01-.5 10.1zm-60-16.5h34.2c-1.6-9.2-7.6-14-16.6-14-9.6 0-15.5 5.5-17.6 14zM271.6 128.6H47.5c-25.9.7-44.4 19.5-44.4 44.7 0 24.9 18.4 43.2 44.4 43.8h224.1c26.7 0 45.7-18.6 45.7-43.9 0-25.6-19-44.6-45.7-44.6zM123.6 54.4H64.5v22.9h29.4c-4.3 10.3-13.3 16.4-25.8 16.4C49.8 93.7 37 80 37 61.3S49.8 29 68.2 29c12.7 0 21.8 6.4 26 17l25.3-12.4C110.6 14.7 92 2.8 68.3 2.8 34.5 2.8 8.8 27.5 8.8 61.3s25.6 58.5 59.5 58.5c32.9 0 55.3-23.5 55.3-53.5V54.4zM478.1 6.9v31.6c-6.9-4.4-14.9-6.6-23.1-6.4-22.7 0-41.8 18.7-41.8 43.7 0 25.1 19.1 44 41.8 44a42 42 0 0023.1-6.4v4.3h28.1V6.9h-28.1zm-18.5 49c11 0 18.4 8.7 18.6 19.5v.6c-.1 11.1-7.6 20-18.6 20-5.7.1-11.1-2.5-14.6-7.1-.2-.3-.5-.6-.7-1l-.3-.4-.9-1.5v-.1c-.3-.6-.5-1.2-.8-1.8l-.6-1.8-.1-.2c-.2-.6-.3-1.2-.4-1.8l-.1-.2c-.3-1.4-.4-2.8-.4-4.2-.1-6 2.4-11.8 6.9-15.8 3.4-2.9 7.6-4.3 12-4.2zM177.3 117.6h183.4c25.9-.7 44.4-19.5 44.4-44.7 0-24.9-18.4-43.2-44.4-43.8H177.3c-26.7 0-45.7 18.6-45.7 43.9 0 25.5 19.1 44.6 45.7 44.6z"/>
<g >
  <rect class="drag__handler" x="159.1" y="53.3" width="218.6" height="40.1"/>
  <path ref="drag-oo" v-bind:style="{'transform': `translateX(${this.current.oo}px)`}" class="drag" d="M178.2 53.3c11.3 0 18.9 8.8 18.9 19.8 0 11.3-7.6 20.3-18.9 20.3-11.6 0-19.1-9-19.1-20.3 0-11 7.4-19.8 19.1-19.8z"/>
</g>

<g>
  <rect  class="drag__handler" x="30.6" y="152.8" width="259.2" height="40.1"/>
  <path ref="drag-o" v-bind:style="{'transform': `translateX(${this.current.o}px)`}" class="drag" d="M270.8 192.9c-11.3 0-18.9-8.8-18.9-19.8 0-11.3 7.6-20.3 18.9-20.3 11.6 0 19.1 9 19.1 20.3 0 10.9-7.5 19.8-19.1 19.8z"/>
</g>

</svg>

    </div>
  </div>

  <div class="app__info" v-bind:style="info" v-if="showInfo">

    <div class="app__card">

    <ul class="app__menu">
      <li><a class="is-active" href="#">contact</a></li>
      <li><a href="https://goodone.digital/posters" target="_blank">shop</a></li>
    </ul>

      <p>Art Director & Frontend developer<br></p>

      <ul>
        <li><a href="mailto:hello@goodone.digital" target="_blank">hello@goodone.digital</a></li>
        <li><a href="tel:+48531088245" target="_blank">+48531088245</a></li>
        <li><a href="http://instagram.com/goodone.digital/" target="_blank">@goodone.digital</a></li>
      </ul>
    </div>

  </div>
   
  </div>
</template>

<script>

import interact from 'interactjs'
import TWEEN from '@tweenjs/tween.js'

class Color {


  constructor(hexOrObject) {
    var obj;
    if (hexOrObject instanceof Object) {
        obj = hexOrObject;
    } else {
        obj = ColorInterpolator.convertHexToRgb(hexOrObject);
    }
    this.r = obj.r;
    this.g = obj.g;
    this.b = obj.b;
  }
  
  asRgbCss() {
      return "rgb("+this.r+", "+this.g+", "+this.b+")";
  }
}

var ColorInterpolator = {
    // convert 6-digit hex to rgb components;
    // accepts with or without hash ("335577" or "#335577")
    convertHexToRgb: function(hex) {
        let match = hex.replace(/#/,'').match(/.{1,2}/g);
        return new Color({
            r: parseInt(match[0], 16),
            g: parseInt(match[1], 16),
            b: parseInt(match[2], 16)
        });
    },
    // left and right are colors that you're aiming to find
    // a color between. Percentage (0-100) indicates the ratio
    // of right to left. Higher percentage means more right,
    // lower means more left.
    findColorBetween: function(left, right, percentage) {
        let newColor = {}, components = ["r", "g", "b"];
        for (var i = 0; i < components.length; i++) 
        {
            let c = components[i];
            newColor[c] = Math.round(left[c] + (right[c] - left[c]) * percentage / 100);
        }
        return new Color(newColor);
    }
}

function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
}


export default {
  name: 'app',

  data() {
    return {
      interact: {},
      showInfo: 1,
    

      current: {
        oo: 0,
        o: 0
      },
     
      max: {
        oo: 0,
        o: 0,
      },

      rootMatrix: 0,
    }
  },

  computed: {
    widget() {
      let bgColor = parseInt((Math.abs(this.current.oo) / this.max.oo) * 100, 10);

      return {
        '--bgColor': this.StepColor('#FF0058', '#ffffff',   bgColor ),
        '--gdColor': this.StepColor('#ffffff', '#FF0058',   bgColor )
      }
    },
    info() {
      let bgColor = parseInt((Math.abs(this.current.o) / this.max.o) * 100, 10);

      return {
        '--gdColor': this.StepColor('#FF0058', '#ffffff',   bgColor ),
        '--bgColor': this.StepColor('#ffffff', '#FF0058',   bgColor )
      }
    }
  },

  methods: {

    Reverse(ev) {

      let fromCoords = { 
        oox: this.current['oo'], 
        // ox: this.current['o'] - this.max['o']
      }

      let toCoords   = { 
        oox: this.max['oo'],
        // ox: this.max['o'] * -1
      }     

      if ( (this.current['oo'] / this.max['oo']) > 0.5 )
        toCoords.oox = 0

      // if ( (this.current['o'] / this.max['o']) < 0.5 )
        // toCoords.ox = this.max['o']

      let tween = new TWEEN.Tween(fromCoords)
        .to(toCoords, 250)
        .easing(TWEEN.Easing.Quadratic.Out) 
        .onUpdate(() => { 
          this.current['oo'] = fromCoords.oox
          // this.current['o'] = fromCoords.ox - this.max['o']
        })
        .start();

    },

    StepColor(from, to, step = 0) {
      return ColorInterpolator.findColorBetween(new Color(from), new Color(to), step).asRgbCss()
    },

    Drag(to) {
      this.max[to] = this.$refs['drag-'+to].parentNode.getBBox().width - this.$refs['drag-'+to].getBBox().width;

      this.interact[to] = interact(this.$refs['drag-'+to])
        .draggable({
          startAxis: 'x',
          inertia: true,

          modifiers: [
            interact.modifiers.restrictRect({
              restriction: 'parent'
            })
          ],
          restrict: { restriction: document.rootElement },

        })
        .on('dragstart', (event) => {
          this.rootMatrix = this.$refs['logo'].getScreenCTM();
        })
        .on('dragmove', (event) => {
          this.current[to] += event.dx / this.rootMatrix.a;
        })
    }
  },

  mounted() {
    this.Drag('o');
    this.Drag('oo');


    requestAnimationFrame(animate);

  }
}
</script>
